import { ScopedCssBaseline } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * Input props for {@link MarkdownTextbox}
 */
export interface MarkdownTextboxProps {
	/**
	 * The markdown-formatted text to be rendered.
	 */
	text: string;
}

/**
 * Markdown textbox
 */
export function MarkdownTextbox(props: MarkdownTextboxProps): React.ReactElement {
	return (
		<ScopedCssBaseline enableColorScheme>
			<ReactMarkdown
				components={{
					// Open external links in new tab
					a({ node, children, ...props }) {
						let url = new URL(props.href ?? '', location.href);
						if (url.origin !== location.origin) {
							props.target = '_blank';
							props.rel = 'noopener noreferrer';
						}

						return <a {...props}>{children}</a>;
					},
				}}
			>
				{props.text}
			</ReactMarkdown>
		</ScopedCssBaseline>
	);
}
