import { type AppState, Auth0Provider, useAuth0, withAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@datapad/utility-components';
import { Button, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import type { Store } from 'redux';
import { refreshAppState } from './Actions';
import RoutedDatapad, { DatapadBanner } from './apps/Datapad';
import * as serviceWorker from './serviceWorker';
import { createAppTheme } from './Theming';

import { DatapadPaper } from './apps/Datapad/Components/DatapadPaper';
import './index.css';
import { createReduxStore } from './Storage';

const appTheme = createAppTheme();

/**
 * App with persisted (cached) redux storage.
 * Note: redux storage is stored in this component's state, as it is keyed off of the signed-in user
 * (to ensure different users on the same browser don't see eachothers' state).
 * Additionally, it is in a separate component to ensure it doesn't get thrashed (mounted and unmounted)
 * when routing locations change.
 */
function AppWithReduxStore({
	userName,
	onLogout,
}: {
	userName: string;
	onLogout: () => void;
}): React.ReactElement {
	const [store, setStore] = useState<Store | undefined>(undefined);

	if (store) {
		return (
			<Provider store={store}>
				<ThemeProvider theme={appTheme}>
					<ConnectedApp userName={userName} logoutFunction={onLogout} />
				</ThemeProvider>
			</Provider>
		);
	} else {
		const store = createReduxStore();
		setStore(store);
		return renderLoadingScreen('Configuring storage...');
	}
}

/**
 * App with user authentication.
 */
function AppWithAuthentication(): React.ReactElement {
	const auth0 = useAuth0();
	const location = useLocation();

	if (auth0.isLoading) {
		return renderLoadingScreen('Authenticating...');
	}

	/**
	 * Redirect function that preserves pathname and search params
	 */
	async function redirect(): Promise<void> {
		auth0.loginWithRedirect({
			appState: {
				returnTo: `${location.pathname}${location.search}`,
			},
		});
	}

	if (auth0.error) {
		return renderLoginError(auth0.error, redirect, auth0.logout);
	}

	if (auth0.isAuthenticated) {
		if (!auth0.user) {
			throw new Error('Authentication failed to provide user data.');
		}

		const userName = auth0.user.nickname as string;
		return <AppWithReduxStore userName={userName} onLogout={auth0.logout} />;
	}

	redirect();

	return renderLoadingScreen('Please sign in to continue...');
}

/**
 * Datapad app.
 * Displays various sub-apps, and offers a menu for navigating them.
 */
const ConnectedApp = connect((reduxState) => reduxState, {
	onRefreshAppState: refreshAppState,
})(RoutedDatapad);

/**
 * App with auth configured
 */
function AppWithAuth(): React.ReactElement {
	const history = useHistory();

	/**
	 * Ensure that app correctly redirects on based user-specified url parameters
	 * @param appState - See {@link AppState}
	 */
	function onRedirectCallback(appState: AppState | undefined): void {
		history.replace(appState && appState.returnTo ? appState.returnTo : window.location.href);
	}

	const InnerApp = withAuth0(AppWithAuthentication);

	return (
		<Auth0Provider
			domain="datapad.us.auth0.com"
			clientId="U0kkfC5BuX8bn9b0TEAh6DOWclZmYyv4"
			authorizationParams={{
				redirect_uri: window.location.origin,
			}}
			onRedirectCallback={onRedirectCallback}
		>
			<InnerApp />
		</Auth0Provider>
	);
}

/**
 * Renders a themed loading screen
 */
function renderLoadingScreen(loadingScreenText: string): React.ReactElement {
	return (
		<ThemeProvider theme={appTheme}>
			<DatapadBanner />
			<DatapadPaper>
				<LoadingScreen text={loadingScreenText} />
			</DatapadPaper>
		</ThemeProvider>
	);
}

/**
 * Renders an error message dialogue for failed login auth.
 * Offers the user the ability to retry, or to log out.
 * @param error - The error that was reported from the auth service.
 * @param loginFunction - Function to be invoked to attempt a new login.
 * @param logoutFunction - Function to be invoked to log out.
 */
function renderLoginError(
	error: Error,
	loginFunction: () => void,
	logoutFunction: () => void,
): React.ReactElement {
	return (
		<>
			<h4>There was a problem signing you in.</h4>
			<br />
			<p>{error.message}</p>
			<br />
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					width: '100%',
				}}
			>
				<Button variant="contained" onClick={() => loginFunction()}>
					Retry
				</Button>
				<Button variant="contained" onClick={() => logoutFunction()}>
					Log Out
				</Button>
			</div>
		</>
	);
}

// TODO
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
	<div
		style={{
			width: '100%',
			height: '100vh',
			color: 'white',
		}}
	>
		<Router>
			<AppWithAuth />
		</Router>
	</div>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
