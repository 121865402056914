import { applyMiddleware, createStore, type Store } from 'redux';
import { logger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import { REFRESH_APP_STATE } from './Actions';
import { reducers as datapadReducers } from './apps/Datapad';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any): unknown => {
	if (action.type === REFRESH_APP_STATE) {
		state = undefined;
	}

	return datapadReducers(state, action);
};

/**
 * Creates a redux store with the appropriate middleware
 */
export function createReduxStore(): Store {
	const middleware = applyMiddleware(thunk, logger);

	return createStore(rootReducer, middleware);
}
