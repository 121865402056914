import {
	type CanCreateCharacter,
	type CanDeleteCharacter,
	type CanEditCharacter,
	type CharacterImageOptions,
	CharacterImageVariant,
	CharacterProfile,
	renderCharacterImage,
} from '@datapad/character-components';
import type { HasProfile } from '@datapad/common-props';
import { ImageContainerShape } from '@datapad/image';
import {
	type Character,
	type NonPlayerCharacter,
	type PlayerCharacter,
	getShortNameOrName,
	getSummaryOrTitle,
} from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import { AccountBox, Close } from '@mui/icons-material';
import {
	Container,
	IconButton,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import type { CanChangeContactSelection, HasContacts } from '../CommonProps.js';
import { InvalidContactSelection } from './InvalidContact.js';
import { type ContactsFilterState, SortBy } from './SortingAndFiltering.js';
import { NarrowToolbar, WideToolbar, toolbarHeightInPixels } from './Toolbar.js';

// TODOs:
// - Fix placement of profile link. Probably need to move it into CharacterProfile (i.e. "viewMore" degree of freedom or something)

/**
 * Input props for {@link ContactsView} and its derivative types.
 */
export interface Props
	extends HasContacts,
		CanChangeContactSelection,
		CanCreateCharacter<NonPlayerCharacter>,
		CanEditCharacter,
		CanDeleteCharacter,
		HasProfile {
	/**
	 * Name of the selected Contact, formatted as a URL string.
	 * @see {@link urlFormat}.
	 */
	selectedContactUrlString: string | undefined;

	/**
	 * Function to link to the `Profile` app with the selected player character.
	 */
	profileLink: (character: PlayerCharacter) => void;
}

const initialState: ContactsFilterState = {
	sorting: SortBy.NameAscending,
	nameFilter: '',
	factionFilter: '',
};

abstract class ContactsView extends React.Component<Props, ContactsFilterState> {
	protected constructor(props: Props) {
		super(props);

		this.state = initialState;
	}

	protected hasContactSelection(): boolean {
		return this.props.selectedContactUrlString !== undefined;
	}

	protected updateSorting(newValue: SortBy): void {
		this.setState({
			...this.state,
			sorting: newValue,
		});
	}

	protected updateNameFilter(newValue: string): void {
		this.setState({
			...this.state,
			nameFilter: newValue,
		});
	}

	protected updateFactionFilter(newValue: string): void {
		console.log(`Faction filter updated to: ${newValue}`);
		this.setState({
			...this.state,
			factionFilter: newValue,
		});
	}

	protected clearAllFilters(): void {
		this.setState(initialState);
	}

	protected getRepresentedFactions(): string[] {
		const representedFactions = new Set<string>();
		if (this.props.contacts) {
			this.props.contacts.forEach((contact) => {
				if (contact.affiliations) {
					contact.affiliations.forEach((faction: string) => {
						representedFactions.add(faction);
					});
				}
			});
		}
		const representedFactionsArray = Array.from(representedFactions.values());
		return representedFactionsArray.sort((a, b) => a.localeCompare(b));
	}

	protected getPlayerCharacterNames(): string[] {
		return this.props.profile.characters.map((character) => character.name);
	}

	protected renderInvalidContact(): React.ReactElement {
		return <InvalidContactSelection />;
	}

	protected renderContactProfile(contact: Character): React.ReactElement {
		const dmMode = this.props.profile.dmMode;
		return (
			<CharacterProfile
				profile={this.props.profile}
				character={contact}
				userCanEdit={dmMode}
				editCharacter={this.props.editCharacter}
				displayEmptyCards={dmMode}
				handleCharacterLink={(characterName) => {
					// If the selected character is the user's signed-in character, navigate
					// to the Profile app, rather than changing contact selection (signed-in
					// character won't appear in contacts list anyways, so such a change would
					// be invalid.
					if (this.props.profile.signedInCharacter?.name === characterName) {
						this.props.profileLink(this.props.profile.signedInCharacter);
					} else {
						this.props.changeContactSelection(characterName);
					}
				}}
				deleteCharacter={this.props.deleteCharacter}
			/>
		);
	}

	/**
	 * Renders a `Profile` app link button for the contact iff they are a player character
	 * owned by the current {@link @datapad/interfaces#Player}.
	 */
	protected renderMaybeProfileLinkButton(contact: Character): React.ReactElement | undefined {
		if (!this.props.profile.doesCharacterBelongToPlayer(contact)) {
			return undefined;
		}

		return this.renderProfileLinkButton(contact as Character as PlayerCharacter);
	}

	private renderProfileLinkButton(character: PlayerCharacter): React.ReactElement {
		return (
			<Tooltip title="View in Profile app">
				<IconButton
					size={'medium'}
					aria-label={'View in Profile App'}
					onClick={() => this.props.profileLink(character)}
				>
					<AccountBox />
				</IconButton>
			</Tooltip>
		);
	}

	protected renderContactsList(): React.ReactElement {
		const contacts = this.getSortedAndFilteredContacts();
		return (
			<div
				style={{
					height: '100%',
					minWidth: '400px',
					overflow: 'auto',
				}}
			>
				<List>{contacts.map((contact) => this.renderListItem(contact))}</List>{' '}
			</div>
		);
	}

	private renderListItem(contact: Character): React.ReactElement {
		const maxImageDimensionInPixels = 80;

		const imageOptions: CharacterImageOptions = {
			maxWidthInPixels: maxImageDimensionInPixels,
			maxHeightInPixels: maxImageDimensionInPixels,
			containerShape: ImageContainerShape.RoundedRectangle,
			variant: CharacterImageVariant.Profile,
			backgroundColor: 'white',
		};

		const maybeAvatarImage = renderCharacterImage(contact.name, imageOptions);

		const summary = getSummaryOrTitle(contact);

		const isSelected = this.isSelected(contact);

		const primaryText = (
			<Typography align="center" variant="h6">
				{getShortNameOrName(contact)}
			</Typography>
		);

		const secondaryText = (
			<Typography align="center" variant="body2">
				{summary}
			</Typography>
		);

		return (
			<ListItemButton
				divider={true}
				selected={isSelected}
				autoFocus={isSelected} // Auto-scroll to selection in the case we navigated via url
				onClick={() => this.props.changeContactSelection(contact.name)}
				key={`contact-list-item-${urlFormat(contact.name)}`}
			>
				<ListItemAvatar>{maybeAvatarImage}</ListItemAvatar>
				<ListItemText primary={primaryText} secondary={secondaryText} />
			</ListItemButton>
		);
	}

	protected isSelected(contact: Character): boolean {
		const urlFormattedContactName = urlFormat(contact.name);
		return urlFormattedContactName === this.props.selectedContactUrlString;
	}

	protected getSelectedContact(): Character | undefined {
		if (!this.props.contacts) {
			throw new Error(
				'Contacts list has not been populated yet. Cannot get "selected" contact.',
			);
		}

		if (!this.props.selectedContactUrlString) {
			return undefined;
		}
		for (const contact of this.props.contacts) {
			if (this.isSelected(contact)) {
				return contact;
			}
		}

		// The selected contact is not in the list of those downloaded.
		// This either means the selection is malformed or the player does not have a character
		// that knows the selected contact.
		// Treat this as no selection.
		return undefined;
	}

	protected getSortedAndFilteredContacts(): Character[] {
		const rawContacts = this.props.contacts;

		if (rawContacts === undefined) {
			throw new Error('Contacts not loaded yet.');
		}

		const filteredContacts = this.filterContacts(rawContacts);
		const sortedContacts = this.sortContacts(filteredContacts, this.state.sorting);
		return sortedContacts;
	}

	private filterContacts(contacts: Character[]): Character[] {
		// Filter to those known by the signed-in character
		let filteredContacts = this.props.profile.getKnownCharacters(contacts);

		// Filter based on name
		const nameFilter = this.state.nameFilter;
		if (nameFilter) {
			filteredContacts = contacts.filter((contact) =>
				contact.name.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase()),
			);
		}

		// Filter based on faction
		const factionFilter = this.state.factionFilter;
		if (factionFilter) {
			filteredContacts = filteredContacts.filter((contact) => {
				return contact.affiliations && contact.affiliations.includes(factionFilter);
			});
		}

		// TODO: apply other filters as needed

		return filteredContacts;
	}

	private sortContacts(contacts: Character[], sorting: SortBy): Character[] {
		return contacts.sort((a, b) => {
			const aName = getShortNameOrName(a);
			const bName = getShortNameOrName(b);

			switch (sorting) {
				case SortBy.NameAscending:
					return aName.localeCompare(bName);
				case SortBy.NameDescending:
					return -aName.localeCompare(bName);
				default:
					throw new Error(`Unrecognized SortBy value: '${sorting}'.`);
			}
		});
	}
}

/**
 * Contacts view for use in narrow screen space (e.g. mobile).
 * Displays either the list xor the selected contact to maximize on screen realestate.
 */
export class NarrowContactsView extends ContactsView {
	public constructor(props: Props) {
		super(props);
	}

	public override render(): React.ReactNode {
		const innerView = this.hasContactSelection()
			? this.renderContactMode(this.getSelectedContact())
			: this.renderListMode();

		return (
			<div
				style={{
					height: '100%',
					width: '100%',
				}}
			>
				{innerView}
			</div>
		);
	}

	/**
	 * Renders a view in the state where a contact is selected.
	 * Displays the contact view with a "close" button.
	 */
	private renderContactMode(contact: Character | undefined): React.ReactElement {
		const closeButton = this.renderCloseButton();
		const profileLinkButton =
			contact === undefined ? undefined : this.renderMaybeProfileLinkButton(contact);

		const view =
			contact === undefined
				? this.renderInvalidContact()
				: this.renderContactProfile(contact);

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					flex: '1',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						width: '100%',
					}}
				>
					{closeButton}
					{profileLinkButton}
				</div>
				{view}
			</div>
		);
	}

	/**
	 * Renders a view in the state where a contact is not selected.
	 * Displays the toolbar and the list of contacts.
	 */
	private renderListMode(): React.ReactElement {
		const toolbar = this.renderToolbar();
		const list = this.renderContactsList();
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					flex: '1',
				}}
			>
				{toolbar}
				{list}
			</div>
		);
	}

	private renderToolbar(): React.ReactElement {
		const factions = this.getRepresentedFactions();

		return (
			<NarrowToolbar
				sorting={this.state.sorting}
				updateSorting={(newValue: SortBy) => this.updateSorting(newValue)}
				nameFilter={this.state.nameFilter}
				updateNameFilter={(newValue: string) => this.updateNameFilter(newValue)}
				factionFilter={this.state.factionFilter}
				factionOptions={factions}
				updateFactionSelection={(newValue: string) => this.updateFactionFilter(newValue)}
				clearAllFilters={() => this.clearAllFilters()}
				createCharacter={this.props.createCharacter}
			/>
		);
	}

	private renderCloseButton(): React.ReactElement {
		return (
			<div
				id="contacts-toolbar-div"
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row-reverse',
					justifyContent: 'space-between',
				}}
			>
				<Tooltip title="Close">
					<IconButton
						onClick={() => this.props.changeContactSelection(undefined)}
						aria-label="close"
					>
						<Close />
					</IconButton>
				</Tooltip>
			</div>
		);
	}
}

/**
 * Contacts view for use in wider screen space.
 * Displays the list next to the contact view.
 */
export class WideContactsView extends ContactsView {
	public constructor(props: Props) {
		super(props);
	}

	public override render(): React.ReactNode {
		const toolbar = this.renderToolbar();
		const view = this.renderView();
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					flex: '1',
				}}
			>
				{toolbar}
				{view}
			</div>
		);
	}

	private renderView(): React.ReactElement {
		const hasContactSelection = this.hasContactSelection();

		let contactView;
		let maybeProfileLinkBar = undefined;
		if (hasContactSelection) {
			const contact = this.getSelectedContact();
			if (contact === undefined) {
				contactView = this.renderInvalidContact();
			} else {
				contactView = this.renderContactProfile(contact);
				maybeProfileLinkBar = this.renderMaybeProfileLinkBar(contact);
			}
		} else {
			contactView = this.renderSelectContactView();
		}

		const renderedList = this.renderContactsList();

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: `calc(100% - ${toolbarHeightInPixels}px)`,
				}}
			>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{renderedList}
				</div>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: '100%',
					}}
				>
					{maybeProfileLinkBar}
					{contactView}
				</div>
			</div>
		);
	}

	private renderMaybeProfileLinkBar(contact: Character): React.ReactElement | undefined {
		const maybeProfileLinkButton = this.renderMaybeProfileLinkButton(contact);
		return (
			<div
				style={{
					position: 'absolute',
					left: '0px',
					top: '0px',
				}}
			>
				{maybeProfileLinkButton}
			</div>
		);
	}

	private renderSelectContactView(): React.ReactElement {
		return (
			<Container
				style={{
					width: '100%',
					height: '100%',
					padding: '15px',
				}}
			>
				Please select a character
			</Container>
		);
	}

	private renderToolbar(): React.ReactElement {
		const factions = this.getRepresentedFactions();

		return (
			<WideToolbar
				sorting={this.state.sorting}
				updateSorting={(newValue: SortBy) => this.updateSorting(newValue)}
				nameFilter={this.state.nameFilter}
				updateNameFilter={(newValue: string) => this.updateNameFilter(newValue)}
				factionFilter={this.state.factionFilter}
				factionOptions={factions}
				updateFactionSelection={(newValue: string) => this.updateFactionFilter(newValue)}
				clearAllFilters={() => this.clearAllFilters()}
				createCharacter={this.props.createCharacter}
			/>
		);
	}
}
