import { CharacterListEntry } from '@datapad/character-components';
import type {
	CanModifyCurrentDate,
	HasBirthdayEvents,
	HasCalendarEvents,
	HasCharacterLink,
	HasDate,
	HasProfile,
} from '@datapad/common-props';
import { ConfirmationDialogue } from '@datapad/form-components';
import { type CalendarEvent, toLongString, toShortString } from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import { Add, Close, Event, SystemUpdateAlt } from '@mui/icons-material';
import {
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import type { CanCreateEvent } from '../CommonProps.js';
import { DialogueMode, EventDialogue } from './EventDialogue.js';
import { FactionIcon } from './FactionIcon.js';

/**
 * Input props for {@link DateCardInternals}
 */
export interface Props
	extends HasDate,
		HasCalendarEvents,
		HasBirthdayEvents,
		HasCharacterLink,
		CanCreateEvent,
		CanModifyCurrentDate,
		HasProfile {
	/**
	 * Function to invoke when the close button is pressed.
	 */
	onClose: () => void;

	/**
	 * Function to invoke when an event is selected.
	 */
	onSelectEvent: (event: CalendarEvent) => void;
}

/**
 * Event insertion status
 */
enum ModalStatus {
	None,
	CreatingEvent,
	EditingCurrentDate,
}

interface State {
	/**
	 * Event insertion modal status
	 */
	modalStatus: ModalStatus;
}

/**
 * Internals of a Date card.
 * Includes the events that occurred on the date.
 */
export class DateCardInternals extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			modalStatus: ModalStatus.None,
		};
	}

	/**
	 * Updates {@link State.modalStatus}
	 */
	private updateModalStatus(modalStatus: ModalStatus): void {
		this.setState({
			...this.state,
			modalStatus,
		});
	}

	private createNewEvent(event: CalendarEvent): void {
		this.props.createEvent(event);
		this.updateModalStatus(ModalStatus.None);
	}

	private updateCurrentDate(): void {
		this.props.updateCurrentDate(this.props.date);
		this.updateModalStatus(ModalStatus.None);
	}

	public override render(): React.ReactNode {
		return (
			<>
				{this.renderMaybeEventCreationModal()}
				{this.renderCardHeader()}
				{this.renderCardBody()}
				{this.renderCardFooter()}
			</>
		);
	}

	private renderCardHeader(): React.ReactElement {
		return (
			<CardHeader
				title={toLongString(this.props.date)}
				avatar={<Event />}
				action={
					<IconButton aria-label="Close" onClick={this.props.onClose}>
						<Close />
					</IconButton>
				}
			/>
		);
	}

	private renderCardBody(): React.ReactElement {
		return (
			<CardContent>
				{this.renderEvents()}
				{this.renderBirthdays()}
			</CardContent>
		);
	}

	private renderEvents(): React.ReactElement {
		if (this.props.events.length === 0) {
			return <></>;
		}

		return (
			<List
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						Events
					</ListSubheader>
				}
			>
				{this.props.events.map((event) => this.renderListItemForEvent(event))}
			</List>
		);
	}

	private renderBirthdays(): React.ReactElement {
		if (this.props.birthdays.length === 0) {
			return <></>;
		}

		return (
			<List
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						Birthdays
					</ListSubheader>
				}
			>
				{this.props.birthdays.map((birthday) => (
					<CharacterListEntry
						characterName={birthday.character.name}
						onClick={() => this.props.handleCharacterLink(birthday.character.name)}
						key={urlFormat(birthday.character.name)}
					/>
				))}
			</List>
		);
	}

	private renderListItemForEvent(event: CalendarEvent): React.ReactElement {
		return (
			<ListItemButton
				key={urlFormat(event.title)}
				onClick={() => this.props.onSelectEvent(event)}
			>
				<ListItemAvatar>
					<FactionIcon event={event} />
				</ListItemAvatar>
				<ListItemText>{event.title}</ListItemText>
			</ListItemButton>
		);
	}

	private renderCardFooter(): React.ReactElement {
		const maybeAddButton = this.props.profile.dmMode ? (
			<Tooltip title="Add a new event">
				<IconButton
					aria-label="Add new event"
					onClick={() => {
						this.updateModalStatus(ModalStatus.CreatingEvent);
					}}
				>
					<Add />
				</IconButton>
			</Tooltip>
		) : undefined;

		const maybeUpdateDateButton = this.props.profile.dmMode ? (
			<Tooltip title={`Set current date to ${toShortString(this.props.date)}`}>
				<IconButton
					aria-label="Update current date"
					onClick={() => {
						this.updateModalStatus(ModalStatus.EditingCurrentDate);
					}}
				>
					<SystemUpdateAlt />
				</IconButton>
			</Tooltip>
		) : undefined;

		return (
			<CardActions>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					{maybeAddButton}
					{maybeUpdateDateButton}
				</div>
			</CardActions>
		);
	}

	private renderMaybeEventCreationModal(): React.ReactElement {
		switch (this.state.modalStatus) {
			case ModalStatus.None:
				return <></>;
			case ModalStatus.CreatingEvent:
				return (
					<ModalCard open={true} onClose={() => this.updateModalStatus(ModalStatus.None)}>
						<EventDialogue
							onClose={() => this.updateModalStatus(ModalStatus.None)}
							onSubmitCalendarEvent={(event) => this.createNewEvent(event)}
							initialEvent={{
								year: this.props.date.year,
								day: this.props.date.dayOfTheYear,
							}}
							mode={DialogueMode.Creation}
						/>
					</ModalCard>
				);
			case ModalStatus.EditingCurrentDate:
				return (
					<ModalCard open={true} onClose={() => this.updateModalStatus(ModalStatus.None)}>
						<ConfirmationDialogue
							onCancel={() => this.updateModalStatus(ModalStatus.None)}
							onConfirm={() => this.updateCurrentDate()}
						>
							<Typography variant="body1">{`Change current date to ${toShortString(
								this.props.date,
							)}?`}</Typography>
						</ConfirmationDialogue>
					</ModalCard>
				);
			default:
				throw new Error(`Unrecognized ModalStatus value: "${this.state.modalStatus}".`);
		}
	}
}
